import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import styles from "./style.module.scss"
import Hamburger from "../hamburger"

const Index = ({ siteTitle, onMenuClick }) => (
  <header className={styles.container}>
    <div
      className={styles.content}
    >
      <h1>
        <Link
          to="/"
          className={styles.pageTitle}
        >
          {siteTitle}
        </Link>
      </h1>
      <Hamburger onClick={() => onMenuClick()}>Menu
      </Hamburger>
    </div>
  </header>
)

Index.propTypes = {
  siteTitle: PropTypes.string
}

Index.defaultProps = {
  siteTitle: ``
}

export default Index
