import React from "react"
import PropTypes from "prop-types"
import { graphql, useStaticQuery } from "gatsby"

import Header from "../header"
import Menu from "../menu"
import styles from "./layout.module.scss"
import PageTitle from "../pagetitle"

const Index = ({ children, title }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  let menu = null

  function toggleMenu() {
    menu.open()
  }

  return (
    <div className={styles.pageContainer}>
      <Header
        siteTitle={data.site.siteMetadata.title}
        onMenuClick={() => toggleMenu()}
      />
      {title && <PageTitle>{title}</PageTitle>}
      <div
        className={styles.childrenContainer}
      >
        <main>{children}</main>

        <Menu ref={el => (menu = el)}/>
        <footer className={styles.footer}>
          <div className={styles.date}>© {new Date().getFullYear()}</div>
          <div className={styles.name}>
            <a href="https://twitter.com/bilikdavid">David Bilík - @bilikdavid</a>
          </div>
          <div className={styles.gatsby}>
            <a href="https://www.gatsbyjs.org">Built with Gatsby</a>
          </div>
        </footer>
      </div>

    </div>
  )
}

Index.propTypes = {
  children: PropTypes.node.isRequired
}

export default Index
