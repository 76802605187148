import React from "react"
import styles from "./style.module.scss"

const Hamburger = ({ onClick }) => {
  return (
    <div
      className={styles.container}
      onClick={onClick}
      role="button"
    >
      <div className={styles.line}/>
      <div className={styles.line}/>
      <div className={styles.line}/>
    </div>
  )
}

export default Hamburger
