import React from "react"
import styles from "./style.module.scss"

const PageTitle = ({ children }) => {
  return (
    <div className={styles.title}>{children}</div>
  )
}

export default PageTitle
